<template>
    <div>
        <el-dialog title="设置时间" v-model="setTimeState" width="50%" :before-close="handleClose">
            <el-form :model='ruleForm' :rules='rules' ref='ruleForm1' label-width='120px' class='demo-ruleForm'>
                <el-form-item label='时间设置：'>
                    <div class='plan-list' v-for='(item,index) in ruleForm.TimePlanEntityGroup' :key="index">
                        <!--                        <el-button type="danger" icon="el-icon-delete" circle class="del-icon-btn"/>-->
                        <div class="del-icon-btn" @click="handleDelTime(item,index)">
                            <el-button type="danger" icon="el-icon-delete" circle />
                        </div>

                        <el-form-item label='周几上课：' label-width='100px'>
                            <el-checkbox-group v-model='item.WeekDay'>
                                <el-checkbox v-for='(val,key) in weekData' :key="key" :label='val.id' name='type'>{{
                                        val.label
                                    }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label='上课时间：' label-width='100px' :prop='`TimePlanEntityGroup[${index}].EndTime`'
                                      :rules='rules.EndTime'>
                            <el-time-select
                                v-model="item.StartTime"
                                :max-time="item.EndTime"
                                class="mr-4"
                                placeholder="选择时间"
                                start="06:30"
                                step="00:15"
                                end="20:30"
                                :key="item.StartTime"
                            />
                            ~
                            <el-time-select
                                v-model="item.EndTime"
                                :min-time="item.StartTime"
                                placeholder="选择时间"
                                start="06:30"
                                step="00:15"
                                end="20:30"
                                :key="item.EndTime"
                            />
                        </el-form-item>
                        <el-form-item label='使用时长：' label-width='100px' style="margin-top: 16px">
                            <el-input-number v-model="item.Hours" @change="handleHoursChange" :min="0"
                                             :max="24"></el-input-number>
                            （小时）
                        </el-form-item>
                    </div>
                    <div class="flex-add-time-btn">
                        <span @click="handleAddTime">
                            <i class="el-icon-circle-plus-outline"></i>
                        添加时间</span>

                    </div>
                </el-form-item>
            </el-form>
            <el-button @click="setTimeState = false">取 消</el-button>
            <el-button type="primary" @click="handleSubmitConfirm">确 定</el-button>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "@/api/api";
import { ElMessage } from 'element-plus'

export default {
    name: "SetBureauTime",
    props: {
        bureauId: {}
    },
    data() {
        return {
            setTimeState: false,
            weekData: [{ id: 1, label: '周一' }, { id: 2, label: '周二' }, { id: 3, label: '周三' }, {
                id: 4,
                label: '周四'
            }, { id: 5, label: '周五' }, { id: 6, label: '周六' }, { id: 0, label: '周日' }],
            ruleForm: {
                StartDate: '',
                EndDate: '',
                TimePlanEntityGroup: [{
                    WeekDay: [],
                    StartTime: '',
                    EndTime: '',
                    Hours: 0,
                }]
            },
            rules: [],

            id:0
        }
    },
    watch: {
        setTimeState(newVal) {
            if (newVal === true) {
                // this.getBureauUnlockTime();
            }

        },
        bureauId(newVal){
            this.id = newVal;
            this.getBureauUnlockTime();
        }
    },
    mounted() {
        /**列出可用时间段*/
        // this.getBureauUnlockTime()
        console.log(this.ruleForm.TimePlanEntityGroup[0].StartTime)
        console.log(this.ruleForm.TimePlanEntityGroup)
    },
    methods: {
        handleClose() {
            this.setTimeState = false;
        },
        /**添加时间*/
        handleAddTime() {
            this.ruleForm.TimePlanEntityGroup = [...this.ruleForm.TimePlanEntityGroup, {
                WeekDay: [],
                StartTime: '',
                EndTime: '',
                Hours: 0,
            }]
        },
        /**列出时间段*/
        getBureauUnlockTime() {
            let params = [
                {
                    bureau_id: this.id,
                },
                "/admin/v1/listUnlockTimeInterval",
                "GET",
            ];
            fetchData(...params).then((res) => {
                console.log(res);
                if (res.code === 200) {
                    let TimePlanEntityGroup = []

                    if (res.data.length !== 0) {
                        res.data.forEach(item => {
                            let WeekDay = [];
                            item.weekday.split(',').forEach(val => {
                                WeekDay = [...WeekDay, parseInt(val)]
                            })
                            console.log(WeekDay)
                            let start_time = item.start_time.substring(0, item.start_time.length - 3)
                            let end_time = item.end_time.substring(0, item.end_time.length - 3)
                            console.log(start_time, end_time)
                            TimePlanEntityGroup = [...TimePlanEntityGroup, {
                                time_interval_id: item.time_interval_id,
                                WeekDay: WeekDay,
                                StartTime: start_time,
                                EndTime: end_time,
                                Hours: item.hours,
                            }]
                        })
                    } else {
                        TimePlanEntityGroup = [{
                            time_interval_id: 0,
                            WeekDay: [],
                            StartTime: '',
                            EndTime: '',
                            Hours: 0,
                        }]
                    }
                    this.ruleForm = {
                        TimePlanEntityGroup: TimePlanEntityGroup
                    }
                    console.log(this.ruleForm)
                }
            });

        },
        /**添加时间**/
        handleSubmitConfirm() {
            this.ruleForm.TimePlanEntityGroup.forEach(item => {
                if (item.time_interval_id !== undefined && item.time_interval_id !== 0) {
                    let weekdays = ''
                    item.WeekDay.forEach(val => {
                        weekdays += val + ','
                    })
                    let weekdays1 = weekdays.substring(0, weekdays.length - 1)
                    let params = [
                        {
                            id: item.time_interval_id,
                            start_time: item.StartTime + ':00',
                            end_time: item.EndTime + ':00',
                            bureau_id: this.id,
                            weekdays: weekdays1,
                            hours: item.Hours
                        },
                        "/admin/v1/updateUnlockTimeInterval",
                        "GET",
                    ];
                    fetchData(...params).then((res) => {
                        console.log(res);
                        this.setTimeState = false;
                        ElMessage({
                            message: '修改成功',
                            type: 'success',
                        })
                    });
                } else {
                    let weekdays = ''
                    item.WeekDay.forEach(val => {
                        weekdays += val + ','
                    })
                    let weekdays1 = weekdays.substring(0, weekdays.length - 1)
                    let params = [
                        {
                            start_time: item.StartTime + ':00',
                            end_time: item.EndTime + ':00',
                            bureau_id: this.id,
                            weekdays: weekdays1,
                            hours: item.Hours
                        },
                        "/admin/v1/createUnlockTimeInterval",
                        "GET",
                    ];
                    fetchData(...params).then((res) => {
                        console.log(res);
                        this.setTimeState = false;
                        ElMessage({
                            message: '设置成功',
                            type: 'success',
                        })
                    });
                }
            })
        },
        /**删除时间*/
        handleDelTime(item, index) {
            if (item.time_interval_id !== undefined && item.time_interval_id !== 0) {
                let params = [
                    {
                        id: item.time_interval_id
                    },
                    "/admin/v1/deleteUnlockTimeInterval",
                    "POST",
                ];
                fetchData(...params).then((res) => {
                    if (res.code === 200) {
                        this.ruleForm.TimePlanEntityGroup.splice(index, 1)
                        ElMessage({
                            message: '删除成功',
                            type: 'success',
                        })
                        if (this.ruleForm.TimePlanEntityGroup.length === 0) {
                            this.ruleForm.TimePlanEntityGroup = [{
                                WeekDay: [],
                                StartTime: '',
                                EndTime: '',
                                Hours: 0,
                            }]
                        }
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-list:not(:first-child) {
    margin-top: 16px;
}

.plan-list {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-bottom: 16px;
}

.flex-add-time-btn {

    //margin: 0 16px;
    //display: flex;
    //align-items: center;
    color: #1184ff;

    span {
        cursor: pointer;
    }

    i {
        color: #1184ff;
        margin-right: 8px;
    }
}

.del-icon-btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 1;
}
</style>
